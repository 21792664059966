<template>
  <FocusTrap>
    <div  id="mycard" class="card">

      <div class="card-header header-elements-inline" style="background-color: whitesmoke; padding: 10px;">
        <h5 v-if="voucher.id == 0" class="card-title">Container Reset Creation</h5>
        <h5 v-if="voucher.id > 0" class="card-title"> Doc No: {{voucher.doc_no}}  Container Reset Updation</h5>
        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload" @click="loadData" ></a>
            <a class="list-icons-item" data-action="remove" @click="closeThis"></a>
          </div>
        </div>
      </div>

    <div class="card-body">

      <div class="row">

        <div class="col-md-1">
          <div class="form-group form-group-material ">
            <label class="control-label text-semibold">Shift</label>
            <select  id="cmbShift"  class="form-control" v-if="voucher"  v-model="voucher.shift.id" autofocus>
              <option v-for="shift in shifts" v-bind:value="shift.id">
                {{ shift.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-2">
        </div>

        <div class="col-md-2">

        </div>

        <div class="col-md-5">

        </div>

        <div class="col-md-2">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Date</label>
            <input type="date" class="form-control" v-if="voucher" v-model="voucher.doc_date">
          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-md-12">

          <!-- Basic layout-->
          <div class="card">


            <div class="card-body">
              <div class="row">
                <!--  Detail Table -->
                <div class="table-responsive">

                  <table id="mytable" class="table table-no-bordered">
                    <thead style="background-color: lightgrey">
                    <tr>
                      <th style="width:50px;">S.No</th>
                      <th style="width: 100px;">Container</th>
                      <th></th>
                      <th >Description</th>
                      <th style="width:150px; text-align: right;">Weight</th>
                      <th style="width: 30px;">Action</th>
                    </tr>

                    </thead>
                    <tbody >
                    <tr v-model="voucher" v-for="(detail,index) in voucher.list" >
                      <td style="text-align: center;padding: 0px;"> {{index + 1}} </td>
                      <td style="padding: 0px;">
                        <input type="text" class="form-control" placeholder="Container" maxlength="10" required v-if="detail" v-model="detail.container.name"  @change="find_container_byname(index, detail.container.name)"   />
                      </td>
                      <td style="padding: 0px;width: 70px;">
                        <button class="btn btn-outline-success" @change="find_container_byname(idx, container.name)" tabindex="-1">Load</button>
                      </td>
                      <td style="padding: 0px;">
                        <input type="text" class="form-control" placeholder="Description" maxlength="100"  v-if="detail" v-model="detail.desc" tabindex="-1"/>
                      </td>
                      <td style="padding: 0px;">
                        <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.wgt" tabindex="-1" readonly />
                      </td>
                      <td style="padding: 0px;text-align: right">
                        <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
                      </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                      <td style="padding: 0px;">
                        <button id="btnAddRow" type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow">
                          <i class="icon-plus3"></i>
                        </button>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td class="text-right" ><span >{{ rowTotalWeight | formatWeight }}</span></td>
                      <td></td>
                    </tr>
                    </tfoot>
                  </table>
                </div>
                <!-- / Detail Table -->
              </div>
            </div>
          </div>
          <!-- /basic layout -->

        </div>


      </div>


      <div class="row">
        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Narration</label>
            <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="voucher" v-model="voucher.remarks" > </textarea>
          </div>
        </div>


        <div class="col-md-6">

        </div>

        <div class="col-md-3 text-right">

          <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="saveVoucher">Save</button>
          <button type="button" id="btnclose" class="btn btn-default" data-dismiss="" @click="clear" >Clear</button>
        </div>
      </div>
    </div>
  </div>
  </FocusTrap>
</template>

<script>

  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'

  export default {
    name: 'ContainerResetForm',
    store,
    components: {

    },
    props: {
      myvoucher: {
        type: Object,
        default: () => JSON.parse('{"id":0,"status":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"0001-01-01","shift":{"id":0,"type":0,"name":""},"remarks":"","list":[]}')
      }
    },
    beforeMount () {
      this.voucher = this.myvoucher;
      this.sumRowAmountTotal();
    },
    data () {
      return {
        shifts:[],
        detailItems:[],
        items : new Map(),
        rowTotalWeight: 0.0,
        voucher: JSON.parse('{"id":0,"status":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"0001-01-01","shift":{"id":0,"type":0,"name":""},"remarks":"","list":[]}'),
      }
    },
    created () {

    },
    mounted () {
      const self = this;
      // self.loadData();

      self.$data.shifts.push({'id':2,'name':'Day'});
      self.$data.shifts.push({'id':3,'name':'Night'});
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');

      $('#cmbShift').focus();

    },
    filters:{
      formatWeight(val){
        return val.toFixed(3);
      }
    },
    methods:{
      indianFormat(val){
        return parseFloat(val).toFixed(2);
      },
      addRow(){
        const self = this;
        self.$data.voucher.list.push(JSON.parse('{"container":{"id":0,"status":0,"type":0,"name":"","wgt":0,"grs_wgt":0},"item":{"id":0,"nature":0,"name":"","wgt":0},"desc":"","wgt":0}'));
        setTimeout(function(){
          $("tbody>tr").last().find("td:eq(1) input").focus();
        }, 99);
      },
      closeThis(){
        this.$emit('containerreset_window_closed');
        if(this.voucher.id > 1){
          this.$modal.hide('voucher-window');
          this.voucher = JSON.parse('{"container":{"id":0,"status":0,"type":0,"name":"","wgt":0,"grs_wgt":0},"item":{"id":0,"nature":0,"name":"","wgt":0},"desc":"","wgt":0}');
        }else{
          this.$router.push("/")
        }
      },
      sumRowAmountTotal() {
        const self = this;

        self.$data.rowTotalWeight = 0.0;
        self.$data.voucher.list.forEach(function (detail) {
          self.$data.rowTotalWeight += parseFloat(detail.wgt);
        });
      },
      clear(){
        const self = this;
        self.$data.voucher = JSON.parse('{"id":0,"status":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"0001-01-01","shift":{"id":0,"type":0,"name":""},"remarks":"","list":[]}');
        self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
        self.$data.containers = [];
      },
      find_container_byname(index, con_name){
        const self = this;

        const requestOptions = {
          method:  'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}api/container/item/name/${con_name}`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){

            delete resp.data.item.unit;

            self.$data.voucher.list[index].container.id = resp.data.id;
            self.$data.voucher.list[index].item = resp.data.item;
            self.$data.voucher.list[index].desc = resp.data.item.p_name;
            self.$data.voucher.list[index].wgt = (resp.data.grs_wgt - resp.data.wgt).toFixed(3);

          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }

          self.sumRowAmountTotal();
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });
      },
      loadData() {
        let self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };

        //Fetch Detail Ledgers
        self.$data.detailItems = [];
        self.$data.items = new Map();
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/item/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            if( !_.isNull(resp.data)){
              self.$data.detailItems = resp.data;
              self.$data.detailItems.forEach(function (itm) {
                self.$data.items.set(itm.id, itm);
              });
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString() ,  type: "error"});
        });

      },
      removeRow(index){
        if (index > -1) {
          this.$data.voucher.list.splice(index, 1);
          this.sumRowAmountTotal();
        }
      },
      saveVoucher(){
        const self = this;

        self.$data.voucher.finyear = store.state.user.finyear;

        let myvoucher =  JSON.parse(JSON.stringify(self.$data.voucher))
        myvoucher.date = moment(self.$data.voucher.date).format('YYYY-MM-DD') + 'T00:00:00Z';

        if (self.$data.voucher.shift.id < 1){
          alert("Invalid Shift");
          return
        }else if(self.$data.voucher.list.length == 0){
          alert("Invalid Details");
          return
        }else {

          myvoucher.list.forEach(function (det) {

            if (det.wgt <= 0){
              alert("Invalid Weight");
              return
            }else if (det.container.id < 1){
              alert("Invalid Container");
              return
            }
            det.wgt = parseFloat(det.wgt);
          });
        }


        const requestOptions = {
          method:  (self.$data.voucher.id == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(myvoucher)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}api/container/reset`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            swal ( { title: "Success!" ,  text: resp.msg, type:  "success"} );

            self.$data.voucher = JSON.parse('{"id":0,"status":0,"type":0,"finyear":0,"doc_no":0,"shift":{"id":0,"type":0,"name":""},"list":[]}');
            self.$emit('reset_saved');

          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oops!" ,  text: `failed to load data!` + err , type:  "error"} );
        });

      }
    }
  }
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}
</style>
